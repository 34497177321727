@import '../css/shared';

.CrewBrandPage {

  img {width: 100%;}

  .product-stats-row {
    .col {
      & > div {
        height: 240px;
        width: 240px;

        &:hover {
          background: var(--bs-primary) !important;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    
    .product-stats-row {
      .col {
        & > div {
          height: 200px;
          width: 200px;
        }
      }
    }

  }

  @include media-breakpoint-down(md) {
    
    .product-stats-row {
      .col {
        & > div {
          height: 150px;
          width: 150px;
        }
      }
    }

  }

  @include media-breakpoint-down(sm) {
    
    .our-values-row > .col:nth-child(1) {
      order: 1 !important;
    }

  }

}