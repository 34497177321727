@import 'css/shared';

.cart-offcanvas {

  .footer-message {
    padding: map-get($spacers, 3) 0 0;
    text-align: center;
  }

  .offcanvas-title span {font-weight: 300;}

  .cart-packs {
    padding: 0 !important;

    .cart-pack + .cart-pack {
      border-top: 1px solid $light;
    }

    .cart-sku {

      & > .row {
        padding: 0.5rem 0 !important;
      }

      .cart-sku-info {

        h6 {
          font-size: $small-font-size;
          font-weight: 400;
        }
        
        ul li {
          font-size: $small-font-size;
          font-weight: 300;
  
          &.extra-small {
            font-size: $font-size-base * 0.75;
            font-weight: 600 !important;
          }
  
        }
    
      }

      .cart-sku-action {
        padding-top: $spacer !important;
  
        .cart-sku-price {
          font-size: $small-font-size;
    
          del {color: $gray-600;}
    
        }
  
        button {color: $gray-900;}
  
      }

    }
  
  }

  .cart-offcanvas-total {
    span {
      font-weight: 600 !important;
    }
  }

  .cart-offcanvas-to-free-shipping {
      font-weight: 600 !important;
  }
  
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-down(md) {}
 
}
