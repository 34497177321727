@import 'css/shared';

.Accordion {
  > .row > [class^='col-'] {
    .accordion-item {
      border-bottom: 0;
    }
    &:last-child {
      .accordion-item {
        border-bottom: 1px solid var(--bs-light);
      }
    }
  }
  .accordion-item {
    border-right: 0;
    border-left: 0;

    .accordion-header {
      .accordion-button {
        &:not(.collapsed) {
          box-shadow: none;
          &::after {
            background-image: none;
            transform: none;
            font-family: 'icomoon';
            content: use-icon('e921');
          }
        }
        &::after {
          background-image: none;
          transform: none;
          font-family: 'icomoon';
          content: use-icon('e91e');
        }
      }
    }
  }

  &.variant-b {
    .accordion-item {
      background-color: $lighter;
      .accordion-header {
        .accordion-button {
          background-color: $lighter;
          text-transform: uppercase;
          &:not(.collapsed) {
            background-color: $lighter;
            &::after {
              content: use-icon('e911');
            }
          }

          &::after {
            content: use-icon('e913');
          }
        }
      }

      @include media-breakpoint-up(md) {
        background-color: $white;
        .accordion-header {
          .accordion-button {
            background-color: $white;
            &:not(.collapsed) {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}
