@import '../css/shared';

.masterclass-page {

  img {width: 100%;}

  .instructions img {
    max-width: 150px;
  }

  .mosaic {

    & > div {
      z-index: 100;
      position: relative;
    }

    &:after {
      content: '';
      position: absolute;
      height: 400px;
      width: 100%;
      background: $primary;
      bottom: 100px;
      z-index: 50;

      @include media-breakpoint-down(lg) {
        bottom: 0;
        height: 100%;
      }

    }
  }

  .masterclass-event {
    border-radius: 20px;
  }

  .accordion {
    .accordion-button {
      font-size: $h5-font-size;

      &:after {
        font-size: 1rem;
      }
    }
  }

  @include media-breakpoint-up(md) {}
  @include media-breakpoint-down(md) {}
}