@import 'css/shared';

.account-offcanvas {
  .offcanvas-title,
  .offcanvas-body-title {
    text-transform: uppercase;
  }
  .offcanvas-body-content {
    font-weight: 300;
  }
  .registration-cta {
    text-transform: uppercase;
  }
}
