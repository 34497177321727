@import '~bootstrap/scss/functions';
@import 'variables';

// RIDEFINISCO ALCUNE COSE SENZA MODIFICARLE, GIUSTO PER AVERLE DIPONIBILI >>>>>

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

// BOOTSTRAP OVERRIDES BEFORE IMPORT >>>>>

$grid-gutter-width: 2rem;

$enable-negative-margins: true;

$custom-container-max-widths: (
  xxl: 1434px,
);

$font-family-sans-serif: 'Montserrat', sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2.1875;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.625;
$h5-font-size: $font-size-base * 1.5;

$small-font-size: 0.875rem;

$font-weight-normal: 400;
$headings-font-weight: $font-weight-normal;

$display-font-sizes: (
  1: 3.75rem,
  2: 3.75rem,
  3: 3.75rem,
  4: 3.75rem,
  5: 1.5rem,
  6: 3.75rem,
);

$yellow: #fab725;
$primary: $yellow;
$pink: #f52199;
$white: #fff;
$black: #000;
$red: #D50C1F;
$gray-900: #787878; // dark
$gray-600: #b7b7b7; // secondary
$gray-200: #e1e1e1; // light
$gray-100: #f6f6f6; // lighter

$light: $gray-200;

$border-color: $gray-200;

$custom-colors: (
  'lighter': $lighter,
  'offer': $pink,
);

$body-bg: $white;
$body-color: $black;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$line-height-base: 1.625;
$input-border-color: $gray-900;

$form-check-input-width: 1.125em;
$form-check-input-border: 1px solid $gray-900;
$form-check-input-border-radius: 0;
$form-check-input-checked-border-color: $gray-900;
$form-check-input-checked-bg-color: $white;
$form-check-input-checked-color: $black;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg viewBox='-2 -2 14 14' xmlns='http://www.w3.org/2000/svg'><rect width='10' height='10' /></svg>");

$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg: $body-bg;
$accordion-button-active-color: $body-color;
$accordion-padding-x: 1rem;

$box-shadow: 0 0.25rem 0.5rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);

$btn-font-weight: 500;

$list-group-color: $body-color;

$offcanvas-horizontal-width: 500px;
$offcanvas-padding-x: 3rem;
$offcanvas-border-width: 0px;

$modal-header-border-width: 0;

$badge-font-size: 0.5625em;
$badge-color: $black;
$badge-font-weight: $font-weight-normal;

// $pagination-border-color: $gray-900;

$dropdown-padding-y: 0;

$alert-padding-y: spacer(2);

$breadcrumb-font-size: 12px;
$breadcrumb-divider-color: $gray-900;
$breadcrumb-active-color: $black !important;
$breadcrumb-divider: quote('');
$breadcrumb-margin-bottom: 0.5rem;

// BOOTSTRAP OVERRIDES BEFORE IMPORT <<<<<

@import '../../node_modules/bootstrap/scss/variables';
//@import '/node_modules/bootstrap/scss/utilities';

$container-max-widths: map-merge($container-max-widths, $custom-container-max-widths);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-text: map-merge($utilities-text, $custom-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge($utilities-bg, $custom-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');
