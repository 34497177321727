@import 'css/shared';

Footer {

  @include media-breakpoint-up(lg) {

    .accordion-item {
      border-bottom: 0 !important;
      .accordion-button {
        font-size: 1rem !important;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }
    
  }

  @include media-breakpoint-down(lg) {

    .footerSocial {
      background: #000000;
      color: #FFFFFF;
    }

    .footerNewsletter {
      background: #fab725;
    }
    .footerPaymentIcons {
      background: #FFFFFF;
    }

    .footerPaymentIcons,
    .footerCopyright {
      padding: 1.5rem 1.25rem;
    }

  }
}