@import '../css/shared';

.SliderG {

  .slider-container {
    position: relative;
    padding: 0 27px;
  }

  @include media-breakpoint-down(xl) {
    .slider-container > .swiper-button-prev,
    .slider-container > .swiper-button-next {
      display: none !important;
    }

    .slider-container {padding: 0;}
  }


}
