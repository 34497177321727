@import '../css/shared';

.account-page {

  .PageWithSubpageHeader {

    .title {text-transform: uppercase;}
    
  }

  .subpage-title-container {
    .subpage-title {
      text-transform: uppercase;
    }
  }

  .menu-account {

    .list-group a {font-weight: 600 !important;}

  }

  .account-table {

    th {font-weight: 600 !important;}

  }

  .inner-box {

    .inner-box-title {
      font-weight: 400 !important;
      text-transform: uppercase;
    }

  }

  .btn-outline-primary {
    text-transform: uppercase;
  }

}