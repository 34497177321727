@import 'css/shared';

.ListA {
  .vr {
    height: 12px;
    min-height: 0;
  }

  .text-initial {
    text-transform: initial;
  }
}
