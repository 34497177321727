@import 'css/shared';

.modal {
  .modal-header {
    padding-bottom: 0;
    .modal-title {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: bold;
    }
  }
  .modal-footer {
    padding-top: 0;
    margin-top: -$modal-footer-margin-between * 0.5;
  }
}
