@import 'css/shared';

.Slider-button {

  svg {
    fill: #000000;
  }

  &.swiper-button-prev {left: 5px;}
  &.swiper-button-next {right: 5px;}

  &.swiper-button-prev.swiper-button-large {padding-right: 5px;}
  &.swiper-button-next.swiper-button-large {padding-left: 5px;}

  &.swiper-button-prev,
  &.swiper-button-next {
    background: #FFFFFF !important;
    margin: auto;
    transition: opacity 0.3s;

    svg {
      width: 20px;
      height: 20px;
    }

    &.swiper-button-large {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25) !important;
      top: 0;
      bottom: 0;    

      svg {
        width: 30px;
        height: 30px;
      }
      
    }

    &.swiper-button-small {
      position: inherit;
    }

    &:after {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0;
      transition: opacity 0s;
    }

  }

  @include media-breakpoint-down(xl) {
    
    &.swiper-button-prev,
    &.swiper-button-next {
      display: none;
    }

  }

}