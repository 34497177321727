@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icons/fonts/icomoon.eot?27wbv4');
  src: url('../fonts/icons/fonts/icomoon.eot?27wbv4#iefix') format('embedded-opentype'),
    url('../fonts/icons/fonts/icomoon.ttf?27wbv4') format('truetype'),
    url('../fonts/icons/fonts/icomoon.woff?27wbv4') format('woff'),
    url('../fonts/icons/fonts/icomoon.svg?27wbv4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima-nova/Proxima-Nova-Thin.eot');
  src: url('../fonts/proxima-nova/Proxima-Nova-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-nova/Proxima-Nova-Thin.woff2') format('woff2'),
    url('../fonts/proxima-nova/Proxima-Nova-Thin.woff') format('woff'),
    url('../fonts/proxima-nova/Proxima-Nova-Thin.ttf') format('truetype'),
    url('../fonts/proxima-nova/Proxima-Nova-Thin.svg#proxima-nova-thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima-nova/Proxima-Nova-Bold.eot');
  src: url('../fonts/proxima-nova/Proxima-Nova-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima-nova/Proxima-Nova-Bold.woff2') format('woff2'),
    url('../fonts/proxima-nova/Proxima-Nova-Bold.woff') format('woff'),
    url('../fonts/proxima-nova/Proxima-Nova-Bold.ttf') format('truetype'),
    url('../fonts/proxima-nova/Proxima-Nova-Bold.svg#proxima-nova-bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

// Dato che il font su web viene renderizzato molto sottile, sono stati effettuati i seguenti cambiamenti:
//
// extra-light:   Montserrat-Light   
// light:         Montserrat-Regular
// regular:       Montserrat-Medium
// medium:        Montserrat-Medium
// semibold:      Montserrat-SemiBold
// bold:          Montserrat-Bold

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: local('Montserrat Light'), url('../fonts/montserrat/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Regular'), url('../fonts/montserrat/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: local('Montserrat Italic'), url('../fonts/montserrat/Montserrat-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Medium'), url('../fonts/montserrat/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), url('../fonts/montserrat/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), url('../fonts/montserrat/Montserrat-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: local('Montserrat Bold'), url('../fonts/montserrat/Montserrat-Bold.woff') format('woff');
}

.proxima {
  font-family: 'Proxima Nova' !important;
}

.fw-thin {
  font-weight: 100 !important;
}
.fw-extra-light {
  font-weight: 200 !important;
}
// .fw-light {
//   font-weight: 300 !important;
// }
// .fw-normal {
//   font-weight: 400 !important;
// }
.fw-medium {
  font-weight: 500 !important;
}
.fw-semi-bold {
  font-weight: 600 !important;
}
// .fw-bold {
//   font-weight: 700 !important;
// }
.fw-extra-bold {
  font-weight: 800 !important;
}
.fw-black {
  font-weight: 900 !important;
}

.extra-small {
  font-size: $font-size-base * 0.75;
}

.display-1,
.display-5 {
  @extend .proxima;
}
