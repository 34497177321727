@import '../css/shared';

.checkout-page {

  .checkout-summary {
    font-weight: 300;

    strong {font-weight: 600;}

    .cart-pack + .cart-pack {
      border-top: 1px solid $light;
    }

    .cart-sku  {
    
      & > .row {
        padding: 0.5rem 0 !important;
      }
  
      .cart-sku-info {
  
        h6 {
          font-size: $small-font-size;
          font-weight: 400;
        }
        
        ul li {
          font-size: $small-font-size;
          font-weight: 300;
  
          &.extra-small {
            font-size: $font-size-base * 0.75;
            font-weight: 600 !important;
          }
  
        }
    
      }

      .cart-sku-price {
        font-size: $small-font-size;

        strong {font-weight: 600;}
        del {color: $gray-600;}
  
      }
    
    }

  }

  @include media-breakpoint-up(md) {}
  @include media-breakpoint-down(md) {}

}