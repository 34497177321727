.scuderia-page .widget-bars-container {
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  gap: 1.5rem;

  .bar {
    width: 6.25rem;
    height: 1.875rem;
    background: #ffb719;
  }
}