@import '../css/shared';

.SliderC {

  @include media-breakpoint-down(xl) {
    .slider-container > .swiper-button-prev,
    .slider-container > .swiper-button-next {
      display: none !important;
    }
  }


  .slider-container {
    position: relative;
    padding-left: 27px;

    @include media-breakpoint-down(xl) {
      padding-left: 0;
    }

    & > .swiper-button-prev,
    & > .swiper-button-next {
      z-index: 200;
    }

    & > .swiper-button-prev {
      left: 0;
      bottom: calc(82px + 1rem);
    }
    & > .swiper-button-next {
      right: calc(7.69% - 40px);
      bottom: calc(82px + 1rem);
    }
  }

  .swiper {
    &.product-slider {
      .slide-action {
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;

        > .wishlist {
          position: absolute;
          right: 10px;
          top: 0;
        }
      }
    }
  }

  .before-product-slider {
    position: relative;
    z-index: 100;
  
    img {
      max-width: 100%;
      width: 100%;
    }
    
    .before-product-slider-content {
      position: relative;
      z-index: 100;
      width: calc(100% - 60px);
  
      &:after {
        content: '';
        position: absolute;
        bottom: 5px;
        right: 10px;
        width: 20px;
        height: 25px;
        background: url(../images/logo-small.svg) no-repeat scroll center center transparent;
        background-size: contain;
      }
  
      @include media-breakpoint-down(lg) {
        width: calc(100% - 40px);
      }
    }
  }

}
