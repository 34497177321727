@import 'css/shared';

.SectionA {

  h3 {
    font-size: 60px;
    line-height: 60px;

    @include media-breakpoint-down(xl) {
      font-size: calc(1.375rem + 1.5vw);
      line-height: calc(1.375rem + 1.5vw);
    }

  }

}