@import '../css/shared';

.CrewEventPage {

  .PageHeader {
    .Section {
      padding-bottom: 0 !important;
    }
  }

}