@import '../css/shared';

.CollectionsPage, .CollectionPage {

  .fit-image img {
    width: 100%;
  }
  
  .sectionBigText {
    h3 {
      font-size: 100px;
    }
  }

  @include media-breakpoint-down(md) {
    
    .image-column {order: 1 !important;}

  }

}

.CollectionPage {
  @include media-breakpoint-down(md) { 
    .textColumn {
      order: 2;
    }
  }
}