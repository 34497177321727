@import 'css/shared';

.NavigationListingToolbar {
  position: relative;
  z-index: 1000;

  .filter-with-children {
    .btn {
      text-transform: initial;
    }

    & > .form-check {
      padding-left: 0;

      input {
        display: none;
      }
    }
  }

  .listing-navigation-available {
    position: relative;

    .btn-wrapper {
      display: inline-block;
      padding-right: 10px;

      & + .btn-wrapper {
        border-left: 1px solid #cccccc;
        padding-left: 20px;
      }
    }

    .listing-navigation-dropdown {
      position: absolute;
      width: 100%;
      top: 100%;
      margin-top: 1px;
    }
  }
}
