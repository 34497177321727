@import 'css/shared';

.Size-guide {
  table {
    width: 100%;
    border: 0;
    border-spacing: 5px;
    border-collapse: separate;

    tr {
      &:nth-child(1),
      &:nth-child(2) {
        td {
          font-weight: 600;
        }
      }

      td {
        &:first-child {
          font-weight: 600;
        }

        background-color: #fbcc66;
        padding: 10px;

        @include media-breakpoint-down(md) {
          padding: 10px 5px;
        }
      }

      &:nth-child(2n + 1) {
        td {
          background-color: var(--bs-light);
        }
      }
    }

    tbody > tr:first-child > td:not(.nologo):first-child {
      background-image: url('./images/logo.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 70%;
    }
  }
}
