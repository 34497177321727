@import '../css/shared';

.LookPage {

  .fit-image img {
    width: 100%;
  }
  

  @include media-breakpoint-down(lg) {
     
  }

  @include media-breakpoint-down(md) {

  }

}