@import '../css/shared';

.SliderA {

  &.absolute-content {

    .slide-background {
      position: relative;
      z-index: 90;

    }

    .slide-content-wrapper {
      position: absolute;
      z-index: 100;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
    }

  }
  
}