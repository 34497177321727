@import '../css/shared';

.cart-page {

  .generic-coupon-form {
    .btn {border-color: $gray-900 !important}
    input:focus + .btn {border-color: $primary !important}
    input.is-invalid + .btn {border-color: $red !important}
  }
  
  .cart-pack + .cart-pack {
    border-top: 1px solid $light;
  }

  .cart-sku  {
    
    & > .row {
      padding: 0.5rem 0 !important;
    }

    .cart-sku-info {

      h6 {
        font-size: $small-font-size;
        font-weight: 400;
      }
      
      ul li {
        font-size: $small-font-size;
        font-weight: 300;

        &.extra-small {
          font-size: $font-size-base * 0.75;
          font-weight: 600 !important;
        }

      }
  
    }
  
    .cart-sku-action {
      padding-top: $spacer !important;

      .cart-sku-price {
        font-size: $small-font-size;

        strong {font-weight: 600;}
        del {color: $gray-600;}
  
      }

      button {color: $gray-900;}

    }
  
  }

  .cart-summary {

    h5, h6 {font-weight: 600;}

    .cart-to-free-shipping {
      font-weight: 600;
      background: #fec;
    }

    .cart-subtotal {font-weight: 300;}

    .cart-coupon {

      .accordion-body {
        padding-top: 0;

        #form-cartPersonalCoupons {
          font-weight: 300;
        }
          
        #form-cartGenericCoupon {
  
          input {border-color: $gray-900 !important}
        
          button:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 1px;
            height: calc(100% - 14px);
            background: $gray-900;
          }
  
        }

      }

    }

    .cart-shipping {
      
      span {font-weight: 600 !important;}
      label span {font-weight: 300 !important;}
    
    }

    .total-to-pay {font-weight: 600;}
    
  }

  .cart-gift-card {
    .gift-card-label {font-weight: 300;}
  }

  .cart-footer h6 {font-weight: 600;}

  @include media-breakpoint-up(md) {}

  @include media-breakpoint-down(md) {}

}