@import '../css/shared';

.SliderD {

  .slider-container {
    position: relative;
    padding: 0 27px;

    @include media-breakpoint-down(xl) {
      padding: 0;
    }

    .swiper-button-prev {
      left: 0;
      bottom: calc(60px + 1em);
    }
    .swiper-button-next {
      right: 0;
      bottom: calc(60px + 1em);
    }

    @include media-breakpoint-down(xl) {
      .swiper-button-prev,
      .swiper-button-next {
        display: none !important;
      }
    }
  }

}
