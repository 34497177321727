@import '../css/shared';

.CrewAmbassadorPage {

  .wallpaper {
    height: 530px;
    margin-bottom: -180px;
  }

  .ambassador-value-list {

    .logo-small {
      position: absolute;
      bottom: 48px;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: 28px;
      height: 35px;
      background: url(../images/logo-small.svg) no-repeat scroll center center transparent;
      background-size: contain;
    }

    @include media-breakpoint-down(md) {
      .logo-small {
        bottom: 15px;
      }
    }

    @include media-breakpoint-up(xl) {

      div:nth-child(1n+4) {
        .value-image   {order: 2}
        .value-content {order: 1}
      } 

    }

    @include media-breakpoint-down(xl) {

      div:nth-child(2n+2) {
        .value-image   {order: 2}
        .value-content {order: 1}
      } 

    }

    @include media-breakpoint-down(md) {

      .value-image   {order: 1 !important}
      .value-content {order: 2 !important}
    
    }


    @include media-breakpoint-up(md)  {.value-image {min-height: 400px;} & > div:nth-child(n+2) .value-image {min-height: 600px;}}
    @include media-breakpoint-up(lg)  {.value-image {min-height: 500px;} & > div:nth-child(n+2) .value-image {min-height: 700px;}}
    @include media-breakpoint-up(xl)  {& > div:nth-child(n+2) .value-image {min-height: 500px;}}
    


  }

  @include media-breakpoint-up(md) {

    .profile-description {
      text-align: center;
    }

    .profile-main-container {
      box-shadow: none !important;
    }

  }

  @include media-breakpoint-down(md) {

    .wallpaper {
      height: 370px;
      margin-bottom: -50px;
    }

    .profile-image {
      width: 200px;
      height: 200px;
      margin: calc((100px + 1rem) * -1) auto 0;

      img {
        width: 100%;
        height: auto;
      }

    }

    .profile-name {text-align: center;}

    td {
      text-align: right;
    }

  }
  
}