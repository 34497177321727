@import '../css/shared';

.form-group {
  .form-label.required:after {
    content: '*';
    margin-left: spacer(1);
    color: $pink;
  }
}

.Table {
  > :not(:first-child) {
    border-top: 0;
  }
}

.Pagination {
  .pagination {
    margin-bottom: 30px !important;
  }

  .arrow-next,
  .arrow-prev,
  .arrow-to-first,
  .arrow-to-last {
    font-size: 0;
    display: flex;
    align-items: center;
    .page-link {
      min-width: 0 !important;
  .arrow-to-last {
    font-size: 10px; // Changed font size to 10px
    display: flex;
    align-items: center;
    .page-link {
      min-width: 0 !important;

      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-size: 11px auto;
        background-color: var(--bs-primary);
        background-repeat: no-repeat;
        background-attachment: scroll;
      }
    }
  }
      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-size: 11px auto;
        background-color: var(--bs-primary);
        background-repeat: no-repeat;
        background-attachment: scroll;
      }
    }
    .page-link {
      padding: 5px; // Added padding
    }
  }
  .arrow-prev {
    a {
      padding-left: 0 !important;
      &:before {
        background-image: url(../images/icons/SVG/small-arrow-left.svg);
        background-position: 4px 5px;
      }
    }
  }
  .arrow-next {
    a {
      padding-right: 0 !important;
      &:before {
        background-image: url(../images/icons/SVG/small-arrow-right.svg);
        background-position: 6px 5px;
      }
    }
  }
  .page-item {
    .page-link {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 2px solid transparent;
      font-weight: 600;
      color: #000000;
      background: transparent;
      display: inline-block;
      text-align: center;
      padding: 0 10px;
      box-shadow: none !important;

      @include media-breakpoint-up(md) {
        min-width: 20px;
      }
    }
    &.active {
      background: transparent;
      .page-link {
        border-color: var(--bs-primary);
      }
    }
  }
}

.account-loyalty-page {
  .page-title {
    font-weight: normal!important;
    text-transform: uppercase;
    font-size: 1rem;
    padding-bottom: spacer(2);
    margin-bottom: spacer(3);
    border-bottom: 1px solid $gray-200;
  }
  .page-subtitle {
    font-weight: $font-weight-light;
  }
  .rule-item {
   
    .item-rule-name {
      min-width: 70px;
    }
    .item-rule-description {
      ul {
        padding-left: 1rem;
      }
    }
  }
  .how-it-works {
    .title {
      font-size: 1rem;
      font-weight: $font-weight-normal!important;
      text-transform: uppercase;
    }
    .description {
      font-weight: $font-weight-light;
    }
  }
  .new-point-bar {
    .step-name {
      text-transform: uppercase;
      font-size: .875rem;
    }
  }
}