@import '../css/shared';

.landing-template-b-page {
  // .section-image {
  // }
  // .section-video {
  // }
  .section-gallery {
    
    > picture {
      display: block;
    } 

    .Slider-button {
      background: rgba(0,0,0,0.6) !important;
      box-shadow: none !important;
      border: 1px solid $red;

      svg {
        fill: $red;
      }

    }

  }
  .section-text {
    @include media-breakpoint-up(md) {
      min-height: 400px;
    }
  }
  .section-products {
    .product-tile {
      .product-image {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          width: 25px;
          overflow: hidden;
          background-size: contain;
          background-repeat: no-repeat;
        }
        // &:after {
        //   content: '';
        //   background-color: white;
        //   background-image: url('../images/logo-small.svg');
        //   position: absolute;
        //   top: 45px;
        //   left: 3px;
        //   width: 25px;
        //   height: 25px;
        //   background-size: contain;
        //   background-repeat: no-repeat;
        //   background-position: center;
        //   padding-top: 10px;
        //   padding-bottom: 10px;
        // }
      }
    }
  }
}
