@import 'functions';
@import 'bootstrap';
@import 'mixins';
@import 'fonts';
@import 'b2x-components';
@import 'b2x-theme/b2x-theme';

// Decommentare questo stile per debuggare i CSS.
// * {
//   background-color: rgba(green, 0.05);
// }

// .btn {
//   text-transform: uppercase;
//   &.no-uppercase {
//     text-transform: none;
//   }
// }

body {
  overflow-y: scroll;
  pointer-events: auto !important; // serve per mantenere l'hover sull'header quando un productTile flottante gli va sopra.
}

.img-placeholder {
  width: 100%;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    background-image: -moz-linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    background-image: -webkit-linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    animation: loading 1s infinite;
    z-index: 45;
  }

  @keyframes loading {
    0% {
      left: -45%;
    }
    100% {
      left: 100%;
    }
  }
}

.CustomerForm {
  .children-container {
    @extend .border-top, .border-bottom, .d-flex, .flex-column, .gap-3;

    .btn-add-children {
      @extend .btn-link, .ps-0, .pe-0;
    }

    .btn-remove-children {
      @extend .btn-sm, .p-0, .extra-small;
    }

    .limit-disclaimer {
      @extend .small, .text-center, .py-2;
    }
  }
}
