@import 'css/shared';

.Section {
  &.section-with-dot {
    padding-top: 160px !important;
    padding-bottom: 60px !important;
    position: relative;

    @include media-breakpoint-down(lg) {
      padding-top: 120px !important;
      padding-bottom: 20px !important;
    }

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100px;
      background: url('./images/white-dot-pattern.svg') repeat-x scroll top center transparent;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
