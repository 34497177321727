@import 'css/shared';

.Button {
  &.btn.btn-outline-link:focus {
    outline: none;
    box-shadow: none;
  }

  &.btn-link {
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}
