@import 'css/shared';
 
.Breadcrumb {

  .breadcrumb {

    .breadcrumb-item {

      line-height: 12px;

      & + .breadcrumb-item:before {
        content: '';
        width: 1px;
        height: 12px;
        background: $gray-300;
        padding: 0;
        margin-right: 0.5rem;
        display: inline-block;
        vertical-align: middle;
      }

      a {color: $gray-900;}
    }

  }

  @include media-breakpoint-down(sm) {
    .breadcrumb {
      display: block;
      overflow: auto;
      white-space: nowrap;

      font-size: 10px;

      .breadcrumb-item {
        display: inline-block;

        &:before {float: none;}
      }
    }
  }
}
