@import 'css/shared';

.BlockQuote {
  padding-left: 60px;
  padding-top: 10px;
  position: relative;

  svg {
    fill: var(--bs-light);
  }

  svg.icon-quote-sx {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.text-center {
    padding: 0;

    svg.icon-quote-sx {
      position: relative;
      top: auto;
      left: auto;
      vertical-align: bottom;
    }
  }

  svg.icon-quote-dx {
    margin-left: 10px;
    display: inline-block;
    vertical-align: top;
  }

  @include media-breakpoint-down(md) {
    padding: 10px 35px 0;

    svg {
        font-size: 34px !important;
        line-height: 34px !important;
    }

  }

}