@import '../css/shared';

.TopSlider {
  min-height: 30px;
  display: flex;
  flex-direction: column-reverse;

  .swiper-slide {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
  
  .swiper-pagination-bullets {
    bottom: 0 !important;
    position: relative
  }
  .swiper-pagination .swiper-pagination-bullet {
    width: 8px !important;
    height: 8px !important; 
    margin: 0 3px !important;
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      background-color: var(--bs-white) !important;
      opacity: .6 !important;
    }

    .swiper-pagination-bullet-active {
      background-color: var(--bs-white) !important;
      opacity: 1 !important;
    }
  }
}