@import 'css/shared';

.EventList {

  & > .row {
    & > .col-lg-6 {
      .text-container {
        padding-top: 50px !important;
      }
    }
  }
  
}
