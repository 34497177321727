@import '../css/shared';

.SliderB {

  .slider-container {
    padding-left: 27px;
    position: relative;

    @include media-breakpoint-down(xl) {
      padding-left: 0;
    }

    .swiper-button-prev {
      left: 0;
    }
    
    .swiper-button-next {
      right: calc(20% - 40px);
    }

    @include media-breakpoint-down(xl) {
      .swiper-button-prev,
      .swiper-button-next {
        display: none !important;
      }
    }

    .swiper {
      &.absolute-content {
        .slide-content-wrapper {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

}
