@import '../css/shared';

:root {
  --swiperColumnGap: 1rem;
  --swiperColumnNumber: 2;
  --swiperPaddingX: 1rem;

  --swiperTotalPaddingX: calc(var(--swiperPaddingX) * 2);
  --swiperTotalColumnGap: calc(var(--swiperColumnGap) * (var(--swiperColumnNumber) - 1));
  --swiperColumnWidth: calc(
    (100% - var(--swiperTotalPaddingX) - var(--swiperTotalColumnGap)) / var(--swiperColumnNumber)
  );
}

@include media-breakpoint-up(xl) {
  :root {
    --swiperColumnNumber: 3;
    --swiperPaddingX: 4rem;
  }
}

.ProductPage {
  .Breadcrumb {
    padding: 0 var(--swiperPaddingX);
    margin: 1rem 0;

    @include media-breakpoint-down(md) {
      margin: 0.5rem 0;
    }
  }

  form {
    .PrimaryInformation {
      position: relative;

      .main-info {
        .new-product-sticker {
          border: 1px solid $black;
          color: $black;
          padding: 0.125rem 0.625rem;
        }

        h1 {
          padding-right: 15px;
        }

        @include media-breakpoint-up(md) {
          height: 100%;
          position: absolute;
          top: 0;
          left: calc(
            var(--swiperColumnWidth) * (var(--swiperColumnNumber) - 1) + var(--swiperPaddingX) +
              var(--swiperTotalColumnGap) - 5px
          );
          width: calc(var(--swiperColumnWidth) + 10px);
          z-index: 100;
        }

        @include media-breakpoint-down(md) {
          position: relative;
        }
      }

      .main-slider {
        .swiper-button-prev {
          left: 1rem;
        }

        .swiper-button-next {
          right: 1rem;
        }

        @include media-breakpoint-up(md) {
          .swiper-button-prev {
            left: calc(var(--swiperPaddingX) + 10px);
          }

          .swiper-button-next {
            right: calc(var(--swiperColumnWidth) + var(--swiperColumnGap) + var(--swiperPaddingX) + 10px);
          }
        }

        @include media-breakpoint-up(md) {
          .swiper {
            padding: 0 var(--swiperPaddingX);
          }
        }

        .swiper-button-prev,
        .swiper-button-next {
          display: block;
          opacity: 1;
          visibility: visible;
        }
      }

      .main-info {
        .sliderAutoWidth {
          .d-inline-block {
            display: inline-block;
          }
        }

        .swiper {
          width: 100% !important;

          label,
          .d-inline-block {
            display: block !important;
            margin: 0 !important;
          }

          .CustomRadio {
            margin: 0 auto !important;
          }

          .d-inline-block {
            padding: 2px 0;
          }
        }

        .size-select-container {
          .Size-guide {
            position: absolute;
            right: 0;
          }
        }
      }
    }

    .product-after-submit {
      display: flex;
      justify-content: space-between;

      .Social-sharing {
        span {
          width: 32px;
          height: 32px;
          position: relative;
          display: inline-block;
          vertical-align: middle;

          svg {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
          }
        }

        button {
          text-transform: none;
        }
      }
    }
  }

  .secondary-information {
    .productImgSidebar {
      max-width: 250px;
    }

    @include media-breakpoint-up(md) {
      .Accordion {
        > .row > [class^='col-'] {
          &:first-child {
            .accordion-item {
              border-top: 0;

              .accordion-header {
                .accordion-button {
                  cursor: default;
                  pointer-events: none;
                  font-weight: bold;

                  &::after {
                    display: none;
                  }
                }
              }

              .accordion-collapse {
                visibility: visible !important;
                display: block !important;
              }
            }
          }
        }
      }
    }
  }

  .product-icon {
    text-align: center;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    line-height: 3.5rem;
  }

  .related-products {
    .slider-container {
      padding: 0 27px;
      position: relative;

      .swiper-button-prev,
      .swiper-button-next {
        z-index: 1000;
      }

      .swiper-button-prev {
        left: 0;
        bottom: calc(60px + 1em);
      }

      .swiper-button-next {
        right: 0;
        bottom: calc(60px + 1em);
      }

      @include media-breakpoint-down(xl) {
        padding: 0;

        .swiper-button-prev,
        .swiper-button-next {
          display: none !important;
        }
      }
    }
  }
}

.product-sticky-footer-a .image-wrapper {
  max-height: 70px;
  max-width: 56px;
}
