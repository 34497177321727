@import '../css/shared';

.SliderE {
  .slide-prova {
    height: 700px;
    background-color: #f6f6f6;
  }

  img {
    max-width: 100%;
  }
  .product-3d-viewer {
    height: 100%;
    background-color: #000;

    iframe {
      height: 100% !important;
    }
  }
}
