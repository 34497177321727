@import 'css/shared';

.CrewHeader {

  .wallpaper {

    .text-container {
      z-index: 100;
      @include media-breakpoint-up(lg) {padding-bottom: 100px;}
    }

  }

}
