@import '../css/shared';

.contest-page {

  img {
    width: 100%;
  }

  .SocialSharing {

    .social-sharing-container  {

      .social-sharing-group {
        top: calc(100% + 20px);
        left: auto;
        right: 0;
      
        &:before {
          left: auto;
          right: 5px;
          top: -9px;
          bottom: auto;
          border-width: 0 8px 9px 8px;
          border-color: transparent transparent #f7f7f7 transparent;
        }

      }

    }

  }

  .contest-description-section {
    
    .video-container {

      video {
        max-width: 400px;
        display: block;
        margin: 0 auto;
      }

      &:before {
        content: '';
        width: 100%;
        height: 350px;
        position: absolute;
        background: $primary;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .img-container {
      position: relative;
      z-index: 90;
    }

    @include media-breakpoint-up(xl) {
      .text-container {
        position: absolute;
        width: 40%;
        right: 5%;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 100;;
      }
    }

  }

  .contest-instructions-section {

    .swiper {
      display: flex;
      flex-direction: column;

      .swiper-pagination {
        order: 2;
        position: relative;
        bottom: 0;
        margin-top: 1.5rem;

        .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
          background-color: var(--bs-light);
        }
      }

    }

    .info-box {
      padding-top: 105px;
      position: relative;
    
      .description-container {
        border-radius: 10px;
      }

      .image-container {
        height: 150px;
        width: 100%;
        top: 0;
        
        img {
          width: auto;
          height: 100%;
        }
      }
    }
  
  }

  .contest-winners-section {
    .text-container > div:after {
      content: "";
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 20px;
      height: 25px;
      background: url(../images/logo-small.svg) no-repeat scroll center center transparent;
      background-size: contain;
    }
  }

  .slider-container {
    position: relative;
    padding: 0 27px;

    @include media-breakpoint-down(xl) {
      padding: 0;
    }

    .swiper-button-prev {
      left: 0;
      bottom: calc(60px + 1em);
    }
    .swiper-button-next {
      right: 0;
      bottom: calc(60px + 1em);
    }

    @include media-breakpoint-down(xl) {
      .swiper-button-prev,
      .swiper-button-next {
        display: none !important;
      }
    }

    .text-container > div:after {
      content: "";
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 20px;
      height: 25px;
      background: url(../images/logo-small.svg) no-repeat scroll center center transparent;
      background-size: contain;
    }
  }

}