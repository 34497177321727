@import 'css/shared';

.SocialSharing {
  .social-sharing-container {
    display: inline-block;
    position: relative;

    span.rounded-circle {
      width: 26px;
    }

    .social-sharing-group {
      align-items: center;
      flex-wrap: wrap;
      position: absolute;
      left: calc(100% + 15px);
      width: 150px;
      top: 0;
      padding: 5px;

      &:before {
        content: '';
        position: absolute;
        left: -9px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 9px 8px 0;
        border-color: transparent #f7f7f7 transparent transparent;
      }
    }
  }
}
